import './css/home.css';

// importing different sections
import Container from './components/container';

function App() {
  return (
    <Container />
  );
}

export default App;
