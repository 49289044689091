import '../../css/home.css';
import '../../css/splash.css';

function App() {
  return (
    <section id="splashPage"> 
        <div class="splashBox">
            <h1>Jessica Lane</h1>
            <h2>Full Stack Developer</h2>
        </div>
    </section>
  );
}

export default App;
